import React, { useState, useEffect } from 'react';

interface RotatingComponentsProps {
  screens: React.ComponentType[]; // Tableau de composants React
  interval?: number; // Intervalle en millisecondes
}

const RotatingComponents: React.FC<RotatingComponentsProps> = ({
  screens,
  interval = 3500,
}) => {
  const [currentScreen, setCurrentScreen] = useState(0);

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentScreen((prev) => (prev + 1) % screens.length); // Boucle infinie
    }, interval);
    return () => clearInterval(rotationInterval);
  }, [screens, interval]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {screens.map((ScreenComponent, index) =>
        index === currentScreen ? (
          <div
            key={index}
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          >
            <ScreenComponent />
          </div>
        ) : null,
      )}
    </div>
  );
};

export default RotatingComponents;
/* import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface RotatingComponentsProps {
  screens: React.ComponentType[]; // Tableau de composants React
  interval?: number; // Intervalle en millisecondes
}

const RotatingComponents: React.FC<RotatingComponentsProps> = ({
  screens,
  interval = 3500,
}) => {
  const [currentScreen, setCurrentScreen] = useState(0);

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentScreen((prev) => (prev + 1) % screens.length); // Boucle infinie
    }, interval);
    return () => clearInterval(rotationInterval);
  }, [screens, interval]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <AnimatePresence mode="wait">
        {screens.map((ScreenComponent, index) =>
          index === currentScreen ? (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              transition={{ duration: 0.8 }}
              style={{ position: 'absolute', width: '100%', height: '100%' }}
            >
              <ScreenComponent />
            </motion.div>
          ) : null,
        )}
      </AnimatePresence>
    </div>
  );
};

export default RotatingComponents; */
