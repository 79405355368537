import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { LogoParkeoBlanc } from '../img/index';
import AnimatedCircles from '../components/AnimatedCircles';

function Menu() {
  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-8 bg-primary p-6">
      <Header title="Menu" logo={LogoParkeoBlanc} />
      <div className="flex min-h-[60svh] flex-col justify-center gap-5 align-middle">
        <Link to="/jardin-st-roch">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            Jardin St Roch
          </button>
        </Link>
        <Link to="/edifice-511">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            Edifice 511
          </button>
        </Link>
        <Link to="/delta-hotel">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            Delta Hotel
          </button>
        </Link>
      </div>
      <p className="mb-4 self-start text-left text-[2vw] font-semibold text-primary">
        Merci pour votre visite !!
      </p>
      <div className="absolute bottom-8 right-8">
        <AnimatedCircles />
      </div>
    </div>
  );
}

export default Menu;
