import React from 'react';

interface PriceLineProps {
  price: string;
  desc?: string;
}

function PriceLine({ price, desc }: PriceLineProps) {
  return (
    <div className="mb-6 flex items-center justify-between border-b-4 border-secondary px-4 s1000:text-left">
      <span className="text-[6vw] font-semibold s1000:text-[4vw]">{price}</span>
      {desc && (
        <span className="text-[6vw] font-semibold s1000:text-[4vw]">
          {desc}
        </span>
      )}
    </div>
  );
}

PriceLine.defaultProps = {
  desc: '',
};

export default PriceLine;
