import React from 'react';
import logo from '../img/logo-stationnement-parkeo.png';
import PriceLine from './PriceLine';
import Header from './Header';
import AnimatedCircles from './AnimatedCircles';

interface Line {
  price: string;
  desc?: string;
}

interface PriceScreenProps {
  title: string;
  lines: Line[];
}

function PriceScreen({ title, lines = [] }: PriceScreenProps) {
  return (
    <div className="flex h-screen w-full flex-col justify-between border-[1vh] border-secondary bg-white font-custom text-primary">
      <div className="flex w-full flex-grow flex-col items-center justify-center">
        <Header title={title} logo={logo} />
        <div className="mt-4 w-full bg-white p-6 pt-0 s1000:pt-6">
          {Array.isArray(lines) &&
            lines.length > 0 &&
            lines.map((lineItem) =>
              lineItem && lineItem.price ? (
                <PriceLine price={lineItem.price} desc={lineItem.desc} />
              ) : null,
            )}
        </div>
      </div>
      <div className="flex">
        <p className="mb-4 px-10 text-left text-[4vw] text-primary s1000:text-[2vw]">
          Merci pour votre visite !!
        </p>
        <div className="absolute bottom-8 right-8">
          <AnimatedCircles />
        </div>
      </div>
    </div>
  );
}

export default PriceScreen;
