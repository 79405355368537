import React from 'react';
import RotatingComponents from '../components/RotatingComponents';
import AutoRefresh from '../components/AutoRefreshProps';
import Edifice511Data from '../content/edifice-511.json';
import DeltaHotelData from '../content/delta-hotel.json';
import ScheduleDisplay from '../components/ScheduleDisplay';
import PriceScreen from '../components/PriceScreen';
import ScreenDelta from '../components/ScreenDelta';
import logo from '../img/logo-stationnement-parkeo.png';
import Header from '../components/Header';
import logoDelta from '../img/delta-logo.png';
import { LogoParkeo } from '../img';
import H1Title from '../components/H1Title';

// Définir les écrans ici
function ScreenOneEnglish() {
  return (
    <div className="flex h-screen w-full flex-col justify-between border-[1vh] border-secondary bg-white font-custom text-primary">
      <div className="flex w-full flex-grow flex-col items-center justify-center">
        <div className="mt-4 w-full bg-white p-6 text-left text-[4vw] s1000:text-[2vw]">
          <H1Title title={'*IMPORTANT*'} />
          <p className="font-bold">HOTEL GUESTS</p>
          <p className="mt-2">
            Before paying for parking, make sure it is not included in your
            reservation.
          </p>
          <p className="mt-4">
            If <span className="font-bold text-secondary">included</span>, ,
            press <span className="font-bold text-secondary">"ticket"</span>.
          </p>
          <p className="mt-4">
            If not, select the number of nights on the screen.
          </p>
        </div>
        <p className="text-left text-[4vw] text-primary s1000:text-[2vw]">
          Thank for your visit !!
        </p>
      </div>
      <div className="mb-4 flex items-center justify-between px-10">
        <img
          className="mb-[1vh] max-w-[50vw]"
          src={LogoParkeo}
          alt="Logo Delta Hotel"
        />
        <img className="max-w-[20vw]" src={logoDelta} alt="Logo Delta Hotel" />
      </div>
    </div>
  );
}

function ScreenTwoEnglish() {
  return (
    <div className="flex h-screen w-full flex-col justify-between border-[1vh] border-secondary bg-white font-custom text-primary">
      <div className="flex w-full flex-grow flex-col items-center">
        <H1Title title={'REGULAR PRICES'} />
        <div className="mt-4 w-full bg-white px-10 py-6 text-left text-primary s1000:px-20">
          <p className="text-[6vw] font-bold s1000:text-[3vw]">10¢/MIN</p>
          <p className="text-[4vw] s1000:text-[2vw]">MAX 21$</p>
          <div className="my-6 border-t-2 border-secondary"></div>
          <p className="text-[6vw] font-bold s1000:text-[3vw]">
            15$
            <span className="ml-auto text-[4vw] font-normal s1000:text-[2vw]">
              EVENING RATE FOR 12 HOURS
            </span>
          </p>
          <p className="mt-4 text-[6vw] font-bold s1000:text-[3vw]">
            HOTEL GUESTS
            <span className="ml-2 text-[4vw] font-normal s1000:text-[2vw]">
              18$/NIGHT(S)
            </span>
          </p>
        </div>
        <p className="text-left text-[5vw] text-primary s1000:text-[2vw]">
          Thank you for your visit !!
        </p>
      </div>
      <div className="mb-4 flex items-center justify-between px-10">
        <img
          className="mb-[1vh] max-w-[50vw]"
          src={LogoParkeo}
          alt="Logo Delta Hotel"
        />
        <img className="max-w-[20vw]" src={logoDelta} alt="Logo Delta Hotel" />
      </div>
    </div>
  );
}

function ScreenOneFrench() {
  return (
    <div className="flex h-screen w-full flex-col justify-between border-[1vh] border-secondary bg-white font-custom text-primary">
      <div className="flex w-full flex-grow flex-col items-center justify-center">
        <div className="mt-4 w-full bg-white p-6 text-left text-[4vw] s1000:text-[2vw]">
          <H1Title title={'*IMPORTANT*'} />
          <p className="font-bold">CLIENT DE L'HÔTEL</p>
          <p className="mt-2">
            Avant de payer pour le stationnement, assurez-vous qu'il n'est pas
            inclus dans votre réservation.
          </p>
          <p className="mt-4">
            Si c'est <span className="font-bold text-secondary">inclus</span>,
            appuyez sur{' '}
            <span className="font-bold text-secondary">"billet"</span>.
          </p>
          <p className="mt-4">
            Si non, choisissez le nombre de nuits à l'écran.
          </p>
        </div>
        <p className="text-left text-[4vw] text-primary s1000:text-[2vw]">
          Merci pour votre visite !!
        </p>
      </div>
      <div className="mb-4 flex items-center justify-between px-10">
        <img
          className="mb-[1vh] max-w-[50vw]"
          src={LogoParkeo}
          alt="Logo Delta Hotel"
        />
        <img className="max-w-[20vw]" src={logoDelta} alt="Logo Delta Hotel" />
      </div>
    </div>
  );
}

function ScreenTwoFrench() {
  return (
    <div className="flex h-screen w-full flex-col justify-between border-[1vh] border-secondary bg-white font-custom text-primary">
      <div className="flex w-full flex-grow flex-col items-center">
        <div className="mt-4 w-full bg-white px-10 py-6 text-left text-primary s1000:px-20">
          <H1Title title={'TARIFS RÉGULIERS'} />
          <p className="text-[6vw] font-bold s1000:text-[3vw]">10¢/MIN</p>
          <p className="text-[4vw] s1000:text-[2vw]">MAX 21$</p>
          <div className="my-6 border-t-2 border-secondary"></div>
          <p className="text-[6vw] font-bold s1000:text-[3vw]">
            15$
            <span className="ml-2 text-[4vw] font-normal s1000:text-[2vw]">
              TARIF DE SOIR POUR 12H
            </span>
          </p>
          <p className="mt-4 text-[6vw] font-bold s1000:text-[3vw]">
            CLIENTS DE L'HÔTEL
            <span className="ml-2 text-[4vw] font-normal s1000:text-[2vw]">
              18$/NUIT(S)
            </span>
          </p>
        </div>
        <p className="text-left text-[5vw] text-primary s1000:text-[2vw]">
          Merci pour votre visite !!
        </p>
      </div>
      <div className="mb-4 flex items-center justify-between px-10">
        <img
          className="mb-[1vh] max-w-[50vw]"
          src={LogoParkeo}
          alt="Logo Delta Hotel"
        />
        <img className="max-w-[20vw]" src={logoDelta} alt="Logo Delta Hotel" />
      </div>
    </div>
  );
}

function DeltaHotel() {
  // Définir les écrans à alterner
  const screens = [
    ScreenOneEnglish,
    ScreenTwoEnglish,
    ScreenOneFrench,
    ScreenTwoFrench,
  ];

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000' }}>
      <AutoRefresh intervalms={900000} />
      <RotatingComponents screens={screens} interval={4000} />
    </div>
  );
}

export default DeltaHotel;
