import React from 'react';

interface titleProps {
  title: string;
}

function H1Title({ title }: titleProps) {
  return (
    <h1 className="text-[8vw] font-bold text-secondary s1000:text-left s1000:text-[5vw]">
      {title}
    </h1>
  );
}

export default H1Title;
